import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { VscOrganization } from "react-icons/vsc";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsKey } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { ImLink } from "react-icons/im";
import { HiCheck } from "react-icons/hi";
import "react-phone-number-input/style.css";
import BASE_URL from "../config";
import PhoneInput from "react-phone-number-input";
import "../styles/onboarding.scss";
import { AppContext } from "../contexts/AppContext";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";

const Onboarding = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [coworkers, setCoworkers] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [codesent, setCodesent] = useState(true);
  const [imageset, setImageSet] = useState(false);
  const [editimage, setEditimage] = useState(false);
  const [error2, setError2] = useState("");
  const profilePictureRef = useRef(null);
  const [step, setStep] = useState(1);

  const { signup } = useContext(AuthContext);
  const { updateprofilepicture, completeonboarding, isLoading } = useContext(AppContext);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleSubmit = async () => {
    if (isLoading === false) {
      try {
        if (company !== "" && name !== "") {
          setError("");
          const response = await completeonboarding(company, name);
          if (response === "success") {
            navigate("/discover");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Signup failed: " + error);
      }
    }
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (isLoading === false) {
      try {
        if (imageAsDataURL !== "") {
          setError2("");
          const response = await updateprofilepicture(imageAsDataURL);
          if (response === "success") {
            setImageSet(false);
            setEditimage(false);
          }
        } else {
          setError2("An error occurred");
          return;
        }
      } catch (error) {
        setError2("Update failed: " + error);
      }
    }

    //add here the upload logic...
  };

  useEffect(() => {
    if (userToken == null) {
      navigate("/");
    }
    else if (userToken.status == 'Email') {
      navigate("/verify");
    }
    else if (userToken.name !== '' && userToken.name !== null) {
      navigate("/discover");
    }
    else if(userToken.teamid.length > 0){
      setStep(2);
    }
  }, []);

  return (
    <div className="onboarding-screen">
      <div className="p-6 pl-8">
        <div className="flex justify-start">
          <Link to={"/"}>
            <img className="h-10 mx-auto" alt="Poppiq Logo" src="/logo-sm.png" />
          </Link>
        </div>
      </div>
      <div className="w-10/12 bg-white rounded-tl-2xl flex-1 p-14 ml-auto">
          {error !== "" && <div className="text-red-500 font-semibold text-md mb-2">{error}</div>}
        <div className="steps font-semibold text-sm text-neutral-800 mb-4">Step {userToken.teamid.length > 0 ? (step - 1) : step} of {userToken.teamid.length > 0 ? 3 : 4}</div>
        <div className="form">
          {step == 1 &&
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div>
              <h1 className="heading">What's the name of your company or team?</h1>
              <h6 className="subheading">This will be the name of your team on Poppiq - choose something that your team will recognise.</h6>
            </div>
            <div className="form-group company">
              <input id="company" name="company" required type="text" className="form-control" placeholder="Company Name" onChange={(e) => setCompany(e.target.value)} value={company} />
            </div>
            <button type="button" className="btn btn-submit mt-5 !px-10" onClick={() => setStep(2)} disabled={company == '' ? true : false}>
                {isLoading === false ? "Next" : <Bounce animating={isLoading} />}
            </button>
          </div>
          }

          {step == 2 &&
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div>
              <h1 className="heading">What's your name?</h1>
              <h6 className="subheading">Adding your name and profile photo helps your co-workers recorgnize and connect with your more easily.</h6>
            </div>
            <div className="form-group name">
              <input id="name" name="name" required type="text" className="form-control" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} />
            </div>
            <div className="form-group photo">
              <label>Your profile photo <span className="font-medium">(optional)</span></label>
              <div className="text-neutral-600 text-[15px] mt-1">Help your co-workers know they're talking to the right person.</div>
              <div className="flex flex-col space-y-3">
                <div className="flex justify-start">
                  <div style={{ backgroundColor: "#FFF" }} className={`rounded-xl photo-div ${editimage === false && ""} `}>
                    {editimage === true ? <ProfilePicture ref={profilePictureRef} onImageLoaded={() => setImageSet(true)} onImageRemoved={() => setImageSet(false)} cropSize={150} frameFormat="circle" /> : ( userToken.image != '' ? <img className="rounded-xl object-contain w-[150px] h-[150px]" src={`data:image/png;base64,${userToken.image}`} /> : <div className="w-[150px] h-[150px] flex justify-center items-center bg-white rounded-xl text-4xl text-neutral-400"><span>{userToken.name[0]+(userToken.lastname != '' ? userToken.lastname[0] : '')}</span></div> )}
                  </div>
                </div>
                <div className="flex justify-start">
                  <div className="space-y-3">
                    {error2 !== "" && <div className="text-center text-red-500 font-semibold text-md">{error2}</div>}
                    <div>
                      {imageset === true && (
                        <button type="button" className="btn-submit-inv w-[150px]" onClick={handleUpload} disabled={isLoading}>
                          {isLoading === false ? "Set Photo" : <Bounce animating={isLoading} />}
                        </button>
                      )}
                      { !editimage && <button type="button" className={`btn-submit-inv w-[150px]`} onClick={() => setEditimage(!editimage)} disabled={isLoading}>
                        Change Photo
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className="btn btn-submit mt-5 !px-10" onClick={() => setStep(3)} disabled={name == '' ? true : false}>
                {isLoading === false ? "Next" : <Bounce animating={isLoading} />}
            </button>
          </div>
          }

          
          {step == 3 &&
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div>
              <h1 className="heading">Who else is on the <b className="text-main">{company}</b> team?</h1>
              <h6 className="subheading">Add co-worker by email</h6>
            </div>
            <div className="form-group coworker">
              <textarea id="company" name="coworkers" required type="text" className="form-control" placeholder="Ex maria@email.se, natalie@email.se" rows={4} onChange={(e) => setCoworkers(e.target.value)} value={coworkers}></textarea>
            </div>
            <div className="flex items-center space-x-5">
              <button type="button" className="btn btn-submit mt-5 !px-10" onClick={() => setStep(2)} disabled={coworkers == '' ? true : false}>{isLoading === false ? "Next" : <Bounce animating={isLoading} />}</button>

              <button type="button" className="btn btn-submit-inv mt-5 flex items-center" onClick={() => setStep(2)}><ImLink /> <span className="ml-2">Copy Invite Link</span></button>
              
              <div onClick={() => setStep(4)} className="cursor-pointer text-sm text-neutral-700 hover:text-black mt-5">Skip this step</div>
            </div>
          </div>
          }


          {step == 4 &&
          <div className="">
            <div>
              <h1 className="heading">Choose a plan to get started:</h1>
            </div>
            <div className="grid grid-cols-3 gap-5">
              <div className="border shadow-lg rounded-lg px-7 py-12">
                <h4 className="text-3xl font-bold mb-5">Free</h4>
                <h4 className="text-4xl font-bold">$0 <span className="text-sm font-medium">USD</span></h4>
                <div className="text-base mt-1">per person/month</div>
                <div className="my-12">
                  <div className="text-lg font-semibold mb-3">Included with Free:</div>
                  <div className="text-sm space-y-2">
                    <div className="flex items-center space-x-3">
                        <HiCheck className="text-green-600"/> <span>Limited influencers on Instagram</span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <HiCheck className="text-green-600"/> <span>Save favourites and create lists</span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <HiCheck className="text-green-600"/> <span>Collaborate with coworkers</span>
                    </div>
                  </div>
                </div>

                <button type="button" className="btn btn-submit-inv !text-base !py-3 !font-semibold mt-5 w-full" onClick={() => handleSubmit()}>Start with Free</button>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
