import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Modal from "../components/Modal";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { PiPlusCircle } from "react-icons/pi";
import "../styles/team.scss";
import Swal from 'sweetalert2';
import { FiChevronDown } from "react-icons/fi";
import { TfiTrash } from "react-icons/tfi";
import { RiEditLine } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import { HiMiniChevronDown } from "react-icons/hi2";
import { GoDotFill } from "react-icons/go";
import { RiMailSendLine } from "react-icons/ri";
import { BiSolidUserRectangle } from "react-icons/bi";
import { BsCheck2All } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";

const Team = () => {
  const { getlists, sendinvitation, resendinvitation, updatemycampaign, deletemycampaign, getmyteam, getmycampaign, isLoading } = useContext(AppContext);

  const [teamMembers, setTeamMembers] = useState([]);
  const [campaign, setCampaign] = useState('');
  const [lists, setLists] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showNewModal, setshowNewModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [listName, setListName] = useState('');
  const [loadingMore, setLoadingMore] = useState(false);
  const [newCampaignName, setnewCampaignName] = useState('');
  const [newStartDate, setnewStartDate] = useState('');
  const [newEndDate, setnewEndDate] = useState('');
  const [newList, setnewList] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [eformLoading, seteFormLoading] = useState(false);
  const [eCampaignName, seteCampaignName] = useState('');
  const [eStartDate, seteStartDate] = useState('');
  const [eEndDate, seteEndDate] = useState('');
  const [eList, seteList] = useState('');
  const [eStatus, seteStatus] = useState('');
  const [iseFormChanged, setIseFormChanged] = useState(false);
  const [editing, setEditing] = useState(false);
  const [toEdit, settoEdit] = useState('');
  const [toDelete, settoDelete] = useState('');
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [resending, setResending] = useState('');
  const [sent, setSent] = useState('');
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const fetchTeam = async () => {
    const response = await getmyteam(statusFilter);
    setTeamMembers(response);
  };

  useEffect(() => {
    // fetchTeam();
  }, [statusFilter]);

  const fetchLists = async () => {
    const response = await getlists();
    setLists(response);
  };

  useEffect(() => {
    fetchLists();
    fetchTeam();
  }, []);

  const handleSendInvitation = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    const response = await sendinvitation(email);
    if(response == 'success'){
    setshowNewModal(false);
    setEmail('');
    fetchTeam();
    }
    setFormLoading(false);
  };

  const handleReSendInvitation = async (teamuserid) => {
    if(resending == '' && sent != teamuserid){
      setResending(teamuserid);
      const response = await resendinvitation(teamuserid);
      if(response == 'success'){
        setSent(teamuserid);
        setResending('');
      }
    }
  };

  const handleEdit = async (i) => {
    settoEdit(i);
    // seteFormLoading(true);
    setshowEditModal(true);
    const response = await getmycampaign(i);
    if(response){
      setCampaign(response[0]);
      seteCampaignName(response[0].name);
      seteStartDate(response[0].startdate);
      seteEndDate(response[0].enddate);
      seteList(response[0].list);
      seteStatus(response[0].status);
    }
  };

  const handleEditCampaign = async (e) => {
    seteFormLoading(true);
    e.preventDefault();
    const response = await updatemycampaign(eCampaignName, eStartDate, eEndDate, eStatus, toEdit);
    if(response == 'success'){
      settoEdit('');
      setshowEditModal(false);
      seteCampaignName('');
      seteStartDate('');
      seteEndDate('');
      seteList('');
      seteStatus('');
      seteFormLoading(false);
      fetchTeam();
    }
  };

  const handleDelete = async () => {
    if (!isLoading) {
      try {
        // const result = await Swal.fire({
        //   title: "Are you sure to delete?",
        //   text: "You won't be able to undo this action",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Yes, Delete",
        // });

        // if (result.isConfirmed) {
        //   let response = await deleteinfluencer(todelete);
        //   if (response === "success") {
        //     fetchInfluencers();
        //   }
        // }
        
        let response = await deletemycampaign(toDelete);
        if (response === "success") {
          setTeamMembers(teamMembers.filter(team => team.userid !== toDelete));
          settoDelete('');
        }

      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  useEffect(() => {
    if (email !== "") {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [email]);

  useEffect(() => {
    if (eCampaignName !== "" && eStartDate !== "" && eEndDate !== "" && eList !== "") {
      setIseFormChanged(true);
    } else {
      setIseFormChanged(false);
    }
  }, [eCampaignName, eStartDate, eEndDate, eList]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      // fetchInfluencers(listId);
    }
  };

  var statusoptions = ['Active','Inactive'];

  return (
    <AppLayout pageId="team" pageName={"Team"}>
      <section>
        <div className="flex justify-between">
            <div className="page-head">
                <h4>Team</h4>
                {teamMembers.length > 0 && teamMembers.length+' team members'}
            </div>
            <div>
                { showNewModal && 
                <Modal heading="Invite" isOpen={showNewModal} onClose={() => setshowNewModal(false)}>
                <form onSubmit={handleSendInvitation}>
                    <div className="modal-body">
                        <div className="space-y-8">
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input id="email" name="email" required type="email" className="form-control" placeholder="name@work-email.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer mt-14 flex justify-end space-x-5">
                    {/* <button type="submit" className="btn-submit">Submit</button> */}
                    { !formLoading && <button type="button" className="btn-cancel" onClick={() => setshowNewModal(false)}> Cancel </button> }
                    <button type="submit" className="btn-submit" disabled={formLoading || !isFormChanged}>
                        {formLoading === false ? "Send Invitation" : <Bounce className="my-2" animating={formLoading} />}
                    </button>
                    </div>
                </form>
                </Modal>
                }
                { showEditModal && 
                <Modal heading="Edit campaign" isOpen={showEditModal} onClose={() => setshowEditModal(false)}>
                <form onSubmit={handleEditCampaign}>
                    <div className="modal-body">
                    {campaign != '' ? 
                    <>
                        <div className="space-y-8">
                            <div>
                                <label>Campaign Name</label>
                                <input className="form-control" type="text" maxLength={30} value={eCampaignName} onChange={(e) => seteCampaignName(e.target.value)} placeholder="Summer campaign" required/>
                            </div>
                            <div className="grid grid-cols-2 gap-10">
                                <div>
                                    <label>Start date</label>
                                    <input className="form-control" type="date" value={eStartDate} onChange={(e) => seteStartDate(e.target.value)} placeholder="Select Date" required/>
                                </div>
                                <div>
                                    <label>End date</label>
                                    <input className="form-control" type="date" value={eEndDate} onChange={(e) => seteEndDate(e.target.value)} placeholder="Select Date" required/>
                                </div>
                            </div>
                            <div>
                                <label>Status</label>
                                <div className="relative">
                                    <FiChevronDown className="absolute right-4 top-4"/>
                                    <select className="form-control" value={eStatus} onChange={(e) => seteStatus(e.target.value)} required>
                                        {/* <option selected disabled>Select</option> */}
                                        {
                                        statusoptions.map((option, index) => {
                                        return(
                                            <option value={option}>{option}</option>
                                        )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </> 
                    : 
                    <div className="flex justify-center w-full py-12">
                      <Spinner color="#CCCCCC" size={24} />
                    </div>
                    }
                    </div>
                    {campaign != '' && <>
                    <div className="modal-footer mt-14 flex justify-end space-x-5">
                    {/* <button type="submit" className="btn-submit">Submit</button> */}
                    { !eformLoading && <button type="button" className="btn-cancel" onClick={() => setshowEditModal(false)}> Cancel </button> }
                    <button type="submit" className="btn-submit" disabled={eformLoading || !iseFormChanged}>
                        {eformLoading === false ? "Save changes" : <Bounce className="my-2" animating={eformLoading} />}
                    </button>
                    </div>
                    </> }
                </form>
                </Modal>
                }
                {
                !userToken.teamid && 
                <button className={`btn-new flex items-center`} onClick={() => setshowNewModal(true)}><PiPlusCircle size={26} className="mr-1.5"/> Invite colleagues</button>
                }
            </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
          { teamMembers.length > 0 ? 
          <>
          <div className="table-wrapper flex-1" onScroll={handleScroll}>
            <table className="table w-full">
              <thead>
                <tr>
                  { !editing && <> 
                    <th scope="col"> Name </th>
                    <th scope="col"> Email </th>
                    <th scope="col"> Status </th>
                    <th scope="col"> Joined </th>
                    <th scope="col"> Action </th>
                  </> }
                </tr>
              </thead>
              <tbody>
                {teamMembers.map((team, index) => {
                  return (
                    <tr key={index}>
                      { toDelete == team.userid ? 
                      <td colSpan={'7'}>
                        <div className="flex justify-end items-center space-x-5">
                          <div className="text-sm">Are you sure?</div>
                          <div><button type="button" className="btn-cancel text-black !py-3 !px-6 !bg-[#f4f4ff] !border-0" onClick={() => settoDelete('')}> No </button></div>
                          <div><button type="button" className="btn-delete !py-3 !px-8 !border-0" onClick={() => handleDelete()}> Delete </button></div>
                        </div>
                      </td>
                      : 
                      <>
                      <td className="name flex items-center">{ team.status != 'Email' && <> { team.image != '' ? 
                            <img className="w-9 h-9 rounded border mr-2" src={`data:image/png;base64,${team.profileimage}`} />
                            : 
                            <BiSolidUserRectangle size={24} className="mr-1.5 text-accent"/>
                          } {team.name} </> }</td>
                      <td className="email">{team.email}</td>
                      <td>
                        <div>
                          <div className="relative mx-auto w-40">
                            { userToken.userid == team.userid ? 
                            <button class={`btn-status flex items-center justify-between`}>
                              <GoDotFill size={12} className={`dot ${team.status === 'Active' ? 'text-green-500' : team.status === 'Email' ? 'text-neutral-400' : team.status === 'Completed' ? 'text-purple-600' : 'text-orange-600'}`} /> <span className="flex-1">{(team.status == 'Email' ? 'Pending' : team.status)}</span> 
                            </button>
                            :
                            <>
                            <button class={`btn-status flex items-center justify-between ${showStatusDropdown == team.userid && '!rounded-b-none'}`} onClick={() => setShowStatusDropdown(showStatusDropdown == team.userid ? '' : team.userid)}>
                              <GoDotFill size={12} className={`dot ${team.status === 'Active' ? 'text-green-500' : team.status === 'Email' ? 'text-neutral-400' : team.status === 'Completed' ? 'text-purple-600' : 'text-orange-600'}`} /> <span className="flex-1">{(team.status == 'Email' ? 'Pending' : team.status)}</span> 
                              <HiMiniChevronDown size={16}/>
                            </button>
                            { showStatusDropdown == team.userid && 
                            <div className="status-dropdown">
                              {
                              statusoptions.map((option, index) => {
                                return(
                                  <div className={`dropdown-item`}>{option}</div>
                                )
                              })
                              }
                            </div> 
                            }
                            </>
                            }
                            
                          </div>
                        </div>
                      </td>
                      <td className="addedatdate">{team.createdat}</td>
                      {/* <td className="text-sm">{team.enddate}</td> */}
                      <td>
                        { team.userid != userToken.userid && 
                        <div className="flex justify-end items-center space-x-5">
                          { team.status != 'Email' ? 
                          <FiEdit3 className="hover:text-blue-500 cursor-pointer" onClick={() => handleEdit(team.userid)} size={20}/>
                          :
                          <>
                          <button className={`btn-new ${resending == team.userid || sent == team.userid ? 'active' : ''} resend-invitation flex items-center`} onClick={() => handleReSendInvitation(team.userid)}>{resending == team.userid ? <div className="flex space-x-3 items-center"><Spinner color="#FFF" size={8} /> <span>Sending...</span></div> : <>{sent == team.userid ? <div className="flex space-x-2 items-center"><FaCheck color="#FFF" size={12} /> <span>Sent</span></div> : 'Resend invitation'}</>}</button>
                          </>
                          }
                          <TfiTrash className="hover:text-red-500 cursor-pointer" onClick={() => settoDelete(team.userid)} size={20}/>
                        </div>
                        }
                      </td>
                      </> }
                    </tr>
                  )
                })}
                {loadingMore && (
                  <tr>
                    <td colSpan="5" className="!text-center !py-10">
                      <Spinner color="#CCCCCC" size={24} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          </>
          :
          <div className="text-center space-y-8 mt-20">
            <img className="mx-auto max-w-8/12" src="/images/no-team.png"/>
            <div className="space-y-2">
              <h4 className="text-xl font-semibold">Work together with your team, share lists and chat</h4>
              <h5 className="text-base">Send an invite to make it easy for them to join</h5>
            </div>
            <div>
              <button className={`btn-new flex items-center mx-auto`} onClick={() => setshowNewModal(true)}><PiPlusCircle size={26} className="mr-1.5"/> Invite colleagues</button>
            </div>
          </div>
          }
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default Team;
