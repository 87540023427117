import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { FiUsers, FiUser, FiLogOut } from "react-icons/fi";
import { FaUsersViewfinder, FaBarsStaggered } from "react-icons/fa6";
import { LuLayoutList, LuSettings } from "react-icons/lu";
import { BiWallet } from "react-icons/bi";
import { PiPassword } from "react-icons/pi";
import { MdDoubleArrow } from "react-icons/md";
import { AppContext } from "../contexts/AppContext";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import "../styles/dashboard.scss";
import "../styles/table.scss";
import { RiCompass3Line } from "react-icons/ri";
import { FaRegHeart } from "react-icons/fa";
import { RiSettings4Line } from "react-icons/ri";
import { RiInformationLine } from "react-icons/ri";
import { BsBarChart } from "react-icons/bs";
import { IoTelescopeOutline } from "react-icons/io5";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { AiOutlineLogout } from "react-icons/ai";
import { HiMiniChevronDown, HiMiniChevronUp } from "react-icons/hi2";
import { PiMagicWand } from "react-icons/pi";
// import { IoMdNotificationsOutline } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BiSolidUserRectangle } from "react-icons/bi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { motion } from "framer-motion";
// import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { BiBell } from "react-icons/bi";

const AppLayout = ({ children, pageName, pageBtn, pageId }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");
  const sidebarRef = useRef(null);
  const { logout, totalUnread } = useContext(AppContext);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  useEffect(() => {
    if (userToken == null) {
      navigate("/");
    }
    else if (userToken.status == 'Email') {
      navigate("/verify");
    }
    else if (userToken.name == null || userToken.name == '') {
      navigate("/onboarding");
    }
  }, []);

  const handleLogout = async () => {
    const response = await logout();
    if (response === "success") {
      navigate("/");
    }
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDropdownToggle = (a) => {
    setActiveDropdown(a == activeDropdown ? '' : a);
  };

  //   // Callback ref to ensure we get the latest node
  //   const setSidebarRef = useCallback((node) => {
  //     sidebarRef.current = node;
  //   }, []);

  //   useEffect(() => {
  //     // Function to check if clicked outside of sidebar
  //     const handleClickOutside = (event) => {
  //       alert("test");
  //       if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //         setIsSidebarOpen(false);
  //       }
  //     };

  //     // Add event listener
  //     document.addEventListener("mousedown", handleClickOutside);

  //     // Clean up
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [sidebarRef]);

  return (
    <>
      {userToken != null ? (
        <div className={`d-flex ${userToken.type} ${isSidebarOpen ? "toggled" : "untoggled"}`} id="wrapper" ref={sidebarRef}>
          <div id="sidebar-wrapper" className="">
            <div className="sidebar-heading">
              <div className="d-flex justify-content-start">
                <div className="flex">
                  <img src="/images/logo.png" className="p-0 logo" />
                  {userToken.type == 'Admin' && <h6 className="font-medium ml-2 text-[22px] -mt-0.5">admin</h6>}
                </div>
              </div>
              {/* <button className="btn" onClick={handleSidebarToggle} id="menu-toggle">
                <MdDoubleArrow color="#FFF" size={42} />
              </button> */}
            </div>
            <div className="list-group list-group-flush pt-1.5 pb-3">
              <div className="w-100">
                <div className="px-2 d-flex">
                  {userToken.type == "Admin" ? (
                    <>
                      <Link to="/dashboard" className={`nav-main ${pageName == "Dashboard" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <RxDashboard /> Dashboard
                        </div>
                        <div className="nav-icon">
                          <RxDashboard />
                        </div>
                      </Link>
                      <Link to="/all-customers" className={`nav-main ${pageName == "Clients" || pageName == "Add Client" || pageName == "Update Client" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <FiUsers /> Clients
                        </div>
                        <div className="nav-icon">
                          <FiUsers />
                        </div>
                      </Link>
                      <Link to="/all-influencers" className={`nav-main ${pageName == "Influencers" || pageName == "Add Influencer" || pageName == "Update Influencer" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <PiMagicWand /> Influencers
                        </div>
                        <div className="nav-icon">
                          <PiMagicWand />
                        </div>
                      </Link>
                      <Link to="" className={`nav-main ${pageName == "All Campaigns" || pageName == "Campaign Details" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <IoMdNotificationsOutline /> Notifications {totalUnread.length > 0 && <span className="inline-flex items-center rounded-sm bg-main-dark px-1.5 py-1.5 text-[10px] font-semibold !text-gray-300 ml-2 leading-none">{totalUnread.length}</span>}
                        </div>
                        <div className="nav-icon">
                          <IoMdNotificationsOutline />
                        </div>
                      </Link>
                      <div>
                        <Link onClick={() => handleDropdownToggle('Settings')} className={`nav-main ${pageName == "Settings" || pageName == "My Profile" || pageName == "Change Password" ? "active-link" : ""}`}>
                          <div className="nav-hide"> <RiSettings4Line  /> Settings </div>
                          <div className="nav-icon"> <RiSettings4Line  /> </div>
                          {/* <div>{(pageName == "Settings" || pageName == "My Profile" || pageName == "Change Password") ? <HiMiniChevronUp/> : <HiMiniChevronDown/>}</div> */}
                          <div><HiMiniChevronDown/></div>
                        </Link>
                        <div className={`ml-9 pl-0.5 flex flex-col text-sm mb-3 ${activeDropdown !== 'Settings' && 'hidden'}`}>
                          <Link to="/profile" className={`p-2 rounded-md ${pageName == "My Profile" ? "active-link" : ""}`}>Profile</Link>
                          <Link to="/password" className={`p-2 rounded-md ${pageName == "Change Password" ? "active-link" : ""}`}>Password</Link>
                          <Link onClick={handleLogout} className="p-2 rounded-md">Logout</Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {userToken.type == "Business" ? (
                    <>
                      <Link to="/my-campaigns" className={`nav-main ${pageName == "My Campaigns" || pageName == "New Campaign" || pageName == "Campaign Details" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <LuLayoutList /> My Campaigns {totalUnread.length > 0 && <span className="inline-flex items-center rounded-sm bg-main-dark px-1.5 py-1.5 text-[10px] font-semibold !text-gray-300 ml-2 leading-none">{totalUnread.length}</span>}
                        </div>
                        <div className="nav-icon">
                          <LuLayoutList />
                        </div>
                      </Link>
                      <Link to="/influencer-analytics" className={`nav-main ${pageName == "Influencer Analytics" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <TbBrandGoogleAnalytics /> Influencer Analytics
                        </div>
                        <div className="nav-icon">
                          <TbBrandGoogleAnalytics />
                        </div>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}

                  {userToken.type == "Influencer" ? (
                    <>
                      <Link to="/assigned-campaigns" className={`nav-main ${pageName == "Assigned Campaigns" || pageName == "New Campaign" || pageName == "Campaign Details" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <LuLayoutList /> Campaign Offers {totalUnread.length > 0 && <span className="inline-flex items-center rounded-sm bg-main-dark px-1.5 py-1.5 text-[10px] font-semibold !text-gray-300 ml-2 leading-none">{totalUnread.length}</span>}
                        </div>
                        <div className="nav-icon">
                          <LuLayoutList />
                        </div>
                      </Link>
                      <Link to="/earnings" className={`nav-main ${pageName == "My Earnings" ? "active-link" : ""}`}>
                        <div className="nav-hide">
                          <BiWallet /> My Earnings
                        </div>
                        <div className="nav-icon">
                          <BiWallet />
                        </div>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}

                  {userToken.type == "Customer" ? (
                    <>
                      <Link to="/discover" className={`nav-main ${pageName == "Discover" || pageName == "Discover influencers" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <RiCompass3Line /> Discover </div>
                        <div className="nav-icon"> <RiCompass3Line /> </div>
                      </Link>
                      <Link to="/lists" className={`nav-main ${pageName == "Lists" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <FaRegHeart  /> Lists </div>
                        <div className="nav-icon"> <FaRegHeart  /> </div>
                      </Link>
                      <Link to="/campaigns" className={`nav-main ${pageName == "Campaigns" || pageName == "Campaign Details" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <LuLayoutList /> Campaigns </div>
                        <div className="nav-icon"> <LuLayoutList /> </div>
                      </Link>
                      <Link to="/monitor" className={`nav-main ${pageName == "Monitor" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <IoTelescopeOutline  /> Monitor </div>
                        <div className="nav-icon"> <IoTelescopeOutline  /> </div>
                      </Link>
                      <Link to="/analytics" className={`nav-main ${pageName == "Analytics" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <BsBarChart  /> Analytics </div>
                        <div className="nav-icon"> <BsBarChart  /> </div>
                        {/* <div><HiMiniChevronDown/></div> */}
                      </Link>
                      <Link to="/team" className={`nav-main ${pageName == "Team" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <FiUsers /> Team </div>
                        <div className="nav-icon"> <FiUsers /> </div>
                      </Link>
                      {/* <div>
                        <Link onClick={() => handleDropdownToggle('Settings')} className={`nav-main ${pageName == "Settings" || pageName == "My Profile" || pageName == "Change Password" ? "active-link" : ""}`}>
                          <div className="nav-hide"> <RiSettings4Line  /> Settings </div>
                          <div className="nav-icon"> <RiSettings4Line  /> </div>
                          <div>{(pageName == "Settings" || pageName == "My Profile" || pageName == "Change Password") ? <HiMiniChevronUp/> : <HiMiniChevronDown/>}</div>
                          <div><HiMiniChevronDown/></div>
                        </Link>
                        <div className={`ml-9 pl-0.5 flex flex-col text-sm mb-3 ${activeDropdown !== 'Settings' && 'hidden'}`}>
                          <Link to="/profile" className={`p-2 rounded-md ${pageName == "My Profile" ? "active-link" : ""}`}>Profile</Link>
                          <Link to="/password" className={`p-2 rounded-md ${pageName == "Change Password" ? "active-link" : ""}`}>Password</Link>
                          <Link onClick={handleLogout} className="p-2 rounded-md">Logout</Link>
                        </div>
                      </div>
                      <Link to="" className={`nav-main ${pageName == "Support" ? "active-link" : ""}`}>
                        <div className="nav-hide"> <RiInformationLine /> Support </div>
                        <div className="nav-icon"> <RiInformationLine /> </div>
                      </Link> */}
                    </>
                  ) : (
                    ""
                  )}
                  <Link to="/" className="nav-main" onClick={handleLogout}>
                    <div className="nav-hide"> <AiOutlineLogout /> Logout </div>
                    <div className="nav-icon"> <AiOutlineLogout /> </div>
                  </Link>
                </div>
                {/* <div className="border-top px-2 d-flex">
                  <br />
                  <p>
                    <i className="fas fa-minus fa-sm"></i>&nbsp; My Account
                  </p>
                  <Link to="/profile" className={`nav-main ${pageName == "My Profile" ? "active-link" : ""}`}>
                    <div className="nav-hide">
                      <FiUser /> My Profile
                    </div>
                    <div className="nav-icon">
                      <FiUser />
                    </div>
                  </Link>
                  <Link to="/change-password" className={`nav-main ${pageName == "Change Password" ? "active-link" : ""}`}>
                    <div className="nav-hide">
                      <PiPassword /> Change Password
                    </div>
                    <div className="nav-icon">
                      <PiPassword />
                    </div>
                  </Link>
                </div> */}
              </div>
              {/* <div className="w-100">
                <div className="border-top px-2 d-flex">
                  <div className="menu-plan space-y-1 px-3">
                    <div className="flex space-x-1 text-sm text-black">
                      <span>Plan: </span>
                      <span className="font-semibold">Free </span>
                    </div>
                    <button className="btn-main flex space-x-3 justify-center items-center"><HiOutlineRocketLaunch size={20}/> <span>Upgrade</span></button>
                  </div>
                  <br />
                  <div className="menu-profile space-y-1 px-3">
                    <div className="flex space-x-3 items-center">
                      { userToken.image != '' ? 
                      <img className="w-16 h-16 rounded-full border" src={`data:image/png;base64,${userToken.image}`} />
                      : 
                      <div className="w-16 h-16 flex justify-center items-center bg-neutral-100 rounded-full text-xl text-neutral-400"><span>{userToken.name[0]+(userToken.lastname != '' ? userToken.lastname[0] : '')}</span></div>
                      }
                      <div className="space-y-0.5">
                        <h6 className="text-base font-bold text-black">{userToken.name + " " + userToken.lastname}</h6>
                        <div className="text-neutral-400 text-sm">{userToken.company}</div>
                      </div>
                    </div>
                  </div>
                  <p><i className="fas fa-minus fa-sm"></i>&nbsp; CONFIGURATION</p>
                        <a href="setup" className="nav-main setup">
                            <div className="nav-hide"><LuSettings/> Settings</div>
                            <div className="nav-icon"><i className="fas fa-cogs"></i></div>
                        </a> 
                </div>
              </div> */}
            </div>
          </div>

          <div id="page-content-wrapper">
            <nav className="">
              <div className="flex flex-wrap items-center justify-end xl:justify-between mx-auto py-2 pr-8">
                <div className="flex w-4/12">
                  <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" className="md:hidden text-gray-500 p-2.5 me-1">
                    <FiSearch />
                  </button>
                  <div className="relative hidden xl:block w-full">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-5 pointer-events-none">
                      <FiSearch color="#999"/>
                    </div>
                    <input type="text" id="search-navbar" className="block w-full p-3 ps-12 text-sm text-gray-900" placeholder="Search in Poppiq"/>
                  </div>
                  <button data-collapse-toggle="navbar-search" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden" aria-controls="navbar-search" aria-expanded="false">
                      <span className="sr-only">Open main menu</span>
                      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                      </svg>
                  </button>
                </div>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto nav-right space-x-4" id="navbar-search">
                  <div className="relative mt-3 md:hidden">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                      </svg>
                    </div>
                    <input type="text" id="search-navbar" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search in Poppiq"/>
                  </div>
                  {userToken.planid == 1 ? <>
                  <div className="pr-3">
                    <Link to={"/plans"}>
                      <div className="flex space-x-2 items-center">
                        <HiOutlineRocketLaunch />
                        <span>Upgrade</span>
                      </div>
                    </Link>
                  </div>
                  <div className="border-l border-neutral-400 h-8 w-[1px]"></div>
                  </> : '' }
                  <ul className="flex flex-row space-x-3 nav-quick-links">
                    <li>
                      <IoMdInformationCircleOutline color="#333" size={24}/>
                    </li>
                    <li className={`${pageId == "settings" ? "active" : ""}`}>
                      <Link to={"/settings"}><HiOutlineCog6Tooth color="#333" size={24}/></Link>
                    </li>
                    <li>
                      <BiBell color="#444" size={24}/>
                    </li>
                  </ul>
                  <div className="border-l border-neutral-400 h-8 w-[1px]"></div>
                  <div className="relative">
                    <button type="button" onClick={() => handleDropdownToggle('User')} className={`user-dropdown px-5 py-1.5 text-center inline-flex items-center rounded-full ${activeDropdown === 'User' && 'bg-hover'}`}>
                      { userToken.image != '' ? 
                        <img className="w-6 h-6 rounded border mr-2" src={`data:image/png;base64,${userToken.image}`} />
                        : 
                        <BiSolidUserRectangle size={24} className="mr-1.5 text-accent"/>
                      }
                      
                      {userToken.name} {activeDropdown === 'User' ? <IoIosArrowUp className="ml-5"/> : <IoIosArrowDown className="ml-5"/> }
                    </button>
                    {activeDropdown === 'User' && 
                    <div id="dropdown" className="z-10 p-4 absolute left-0 bg-white space-y-3 rounded shadow w-full mt-2">
                        <div className="inline-flex items-center">
                          { userToken.image != '' ? 
                            <img className="w-9 h-9 rounded border mr-2" src={`data:image/png;base64,${userToken.image}`} />
                            : 
                            <BiSolidUserRectangle size={24} className="mr-1.5 text-accent"/>
                          }
                          
                          <div>
                            <div className="text-md font-medium leading-none">{userToken.name}</div>
                            <div className="text-[11px] font-normal text-neutral-500">{userToken.company}</div>
                          </div>
                          
                        </div>
                        <div>
                          <hr className="bg-gray-100"/>
                        </div>
                        <div className="flex flex-col text-[13px] text-neutral-700 font-medium space-y-2">
                          <Link to="/profile">Account</Link>
                          <Link to="/team">Team</Link>
                          <Link to={"/plans"}>Plans &amp; Billing</Link>
                          <Link>Order History</Link>
                          <Link to="/settings">Settings</Link>
                        </div>
                        <div>
                          <hr className="bg-gray-100"/>
                        </div>
                        <div className="text-[13px] text-neutral-700 font-medium space-y-2">
                          <Link to="/" onClick={handleLogout}>Logout</Link>
                        </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </nav>

            <div className="main-body" id={pageId != "" ? pageId : ""}>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <div id="menu-toggle-sm"></div>
              { (pageName != 'Discover influencers' && pageName != 'Lists' && pageName != 'Campaigns' && pageName != 'Monitor' && pageName != 'Analytics' && pageName != 'Team' && pageName != 'Campaign Details') && 
              <div className="page-head flex justify-between lg:mx-3">
                  <div><h4>{pageName != "" ? pageName : ""}</h4></div>
                  { pageBtn && <div className="page-head-btn">{pageBtn}</div> }
              </div>
              }
              <div className="lg:mx-3">{children}</div>
              </motion.div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AppLayout;
