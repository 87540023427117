import React, { useState, useContext, useRef, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import { Link } from "react-router-dom";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import "../styles/settings.scss";
import { HiArrowNarrowRight } from "react-icons/hi";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import { AppContext } from "../contexts/AppContext";
import { VscOrganization } from "react-icons/vsc";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineUser } from "react-icons/ai";
import { LiaPhoneSolid } from "react-icons/lia";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import "react-phone-number-input/style.css";
import { PiPaypalLogo } from "react-icons/pi";
import PhoneInput from "react-phone-number-input";
import { BiSolidUserRectangle } from "react-icons/bi";

export default function MyProfile() {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [name, setName] = useState(userToken.name);
  const [lastname, setLastname] = useState(userToken.lastname);
  const [company, setCompany] = useState(userToken.company);
  const [phone, setPhone] = useState(userToken.phone);
  const [email, setEmail] = useState(userToken.email);
  const [type, setType] = useState(userToken.type);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [editing, setEditing] = useState("");
  const [imageset, setImageSet] = useState(false);
  const [editimage, setEditimage] = useState(false);
  const profilePictureRef = useRef(null);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const { updateprofile, updateprofiledetail, updateprofilepicture, isLoading } = useContext(AppContext);

  useEffect(() => {
    if (userToken.image !== null && userToken.image !== "") {
      const PP = profilePictureRef.current;
    }
  }, [userToken]);

  useEffect(() => {
    if(userToken.name.type === 'Admin'){
      if (name !== userToken.name || email !== userToken.email) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
    else{
      if (company !== userToken.company || name !== userToken.name || lastname !== userToken.lastname || phone !== userToken.phone) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
  }, [company, userToken.company, name, userToken.name, lastname, userToken.lastname, phone, userToken.phone]);

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (isLoading === false) {
      try {
        if (imageAsDataURL !== "") {
          setError2("");
          const response = await updateprofilepicture(imageAsDataURL);
          if (response === "success") {
            setImageSet(false);
            setEditimage(false);
          }
        } else {
          setError2("An error occurred");
          return;
        }
      } catch (error) {
        setError2("Update failed: " + error);
      }
    }
  };

  const handleUpdate = async (valtype, val) => {
    if (isLoading === false && editing != '') {
      try {
          const response = await updateprofiledetail(valtype, val);
          if(response == 'success'){
            setError("");
            setEditing('');
          }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false && isFormChanged === true) {
      try {
        if (name !== "" && email !== "") {
          setError("");
          setIsFormChanged(false);
          const response = await updateprofile(company, name, lastname, email, phone);
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  return (
    <AppLayout pageId="settings" pageName={`Welcome to your account, ${userToken.name}`}>
      <section>
        <main className="settings">
          <div className="space-x-10 w-full lg:w-9/12 md:w-full p-10 main-bg rounded-lg">
            <div className="space-y-16 profile-form">

              <div className="form-group flex flex-row justify-between items-center profile">
                <div className="w-1/6">
                  <label htmlFor="profile">Profile photo</label>
                </div>
                <div className="w-3/6 flex justify-center">
                  {editing == 'profile' ? 
                  <ProfilePicture ref={profilePictureRef} onImageLoaded={() => setImageSet(true)} onImageRemoved={() => setImageSet(false)} cropSize={150} frameFormat="circle" />
                  :
                  <>
                  { userToken.image != '' ? 
                    <img className="w-16 h-16 rounded mx-auto border" src={`data:image/png;base64,${userToken.image}`} />
                    : 
                    <BiSolidUserRectangle size={24} className="mx-auto text-accent"/>
                  }
                  </>
                  }
                </div>
                <div className="w-2/6 flex space-x-5 justify-end">
                  { editing == 'profile' && 
                  <>
                  {imageset === true && (
                    <button type="button" className="btn-submit" onClick={handleUpload} disabled={isLoading}>
                      {isLoading === false ? "Set Picture" : <Bounce animating={isLoading} />}
                    </button>
                  )}
                  </>
                  }
                  <button className="btn-new" onClick={() => {setEditing(editing == 'profile' ? '' : 'profile'); setImageSet(false);}}>{editing == 'profile' ? 'Cancel' : 'Edit'}</button>
                </div>
              </div>


              <div className="form-group flex flex-row justify-between items-center name">
                <div className="w-1/6">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="w-3/6">
                  {editing == 'name' ? 
                  <input id="name" name="name" required type="text" className="form-control" placeholder="John" value={name} onChange={(e) => setName(e.target.value)} maxLength={50} />
                  :
                  <h6>{userToken.name}</h6>
                  }
                </div>
                <div className="w-2/6 flex space-x-5 justify-end">
                  { editing == 'name' && 
                  <button className="btn-submit" onClick={() => handleUpdate('name', name)}>Submit</button>
                  }
                  <button className="btn-new" onClick={() => setEditing(editing == 'name' ? '' : 'name')}>{editing == 'name' ? 'Cancel' : 'Edit'}</button>
                </div>
              </div>


              <div className="form-group flex flex-row justify-between items-center company">
                <div className="w-1/6">
                  <label htmlFor="company">Company</label>
                </div>
                <div className="w-3/6">
                  {editing == 'company' ? 
                  <input id="company" name="company" required type="text" className="form-control" placeholder="John" value={company} onChange={(e) => setCompany(e.target.value)} maxLength={50} />
                  :
                  <h6>{userToken.company}</h6>
                  }
                </div>
                <div className="w-2/6 flex space-x-5 justify-end">
                  { editing == 'company' && 
                  <button className="btn-submit" onClick={() => handleUpdate('company', company)}>Submit</button>
                  }
                  <button className="btn-new" onClick={() => setEditing(editing == 'company' ? '' : 'company')}>{editing == 'company' ? 'Cancel' : 'Edit'}</button>
                </div>
              </div>


              <div className="form-group flex flex-row justify-between items-center email">
                <div className="w-1/6">
                  <label htmlFor="company">Email</label>
                </div>
                <div className="w-3/6">
                  <h6 className="text-neutral-400">{userToken.email}</h6>
                </div>
                <div className="w-2/6 flex space-x-5 justify-end">
                </div>
              </div>


              <div className="form-group flex flex-row justify-between items-center phone">
                <div className="w-1/6">
                  <label htmlFor="phone">Phone</label>
                </div>
                <div className="w-3/6">
                  {editing == 'phone' ? 
                  <input id="phone" name="phone" required type="tel" className="form-control" placeholder="John" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={20} />
                  :
                  <h6>{userToken.phone != '' ? <div className="text-neutral-400">not added</div> : userToken.phone}</h6>
                  }
                </div>
                <div className="w-2/6 flex space-x-5 justify-end">
                  { editing == 'phone' && 
                  <button className="btn-submit" onClick={() => handleUpdate('phone', phone)}>Submit</button>
                  }
                  <button className="btn-new" onClick={() => setEditing(editing == 'phone' ? '' : 'phone')}>{editing == 'phone' ? 'Cancel' : 'Edit'}</button>
                </div>
              </div>

              <div className="flex">
                <Link to={"/password"} className="btn btn-submit btn-change-password">Change Password</Link>
              </div>

                    {/* <button type="submit" className="btn-submit text-center justify-center !py-4" disabled={isLoading || !isFormChanged}>
                      {isLoading === false ? "Save Changes" : <Bounce className="my-2" animating={isLoading} />}
                    </button> */}

            </div>
            </div>
        </main>
      </section>
    </AppLayout>
  );
}
