import STRIPE_PUBLIC_KEY from "../config";
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import BASE_URL from "../config";
import AppLayout from '../layouts/AppLayout';
import { HiCheck } from 'react-icons/hi';
import Spinner from 'react-activity/dist/Spinner';
import "react-activity/dist/Spinner.css";
import { PiStarFour } from "react-icons/pi";
import { AppContext } from '../contexts/AppContext';
import { loadStripe } from '@stripe/stripe-js'; 
import { useLocation } from 'react-router-dom';
import { format } from "date-fns";
import { GrStatusInfo } from "react-icons/gr";

const Plans = () => {
  const userToken = JSON.parse(localStorage.getItem('userToken'));
  const stripePromise = loadStripe("pk_test_51Q0rJkDso1X1XO3wQGI6vAVd67LqyUzUqECWOw3e3vemiCZrDYv2s6126fsGl7bfIj0EQ95YlJLkyWDXtLzaiS1B00IUbOodUi");

  const { subscribePlan, isLoading } = useContext(AppContext);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);

  const location = useLocation();

  // Extract the session_id from the URL
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');

  useEffect(() => {
    const fetchPlans = async () => {
      setIsPageLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/plans/all`);
        setPlans(response.data.plans);
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        setIsPageLoading(false);
      }
    };

    const fetchCurrentPlan = async () => {
      setIsLoadingPlan(true);
      try {
        const response = await axios.post(`${BASE_URL}/user/getCurrentPlan`, {
          userId: userToken.userid,
        });
        setCurrentPlan(response.data.plan);
      } catch (error) {
        console.error('Error fetching current plan:', error);
      } finally {
        setIsLoadingPlan(false);
      }
    };

    fetchPlans();
    fetchCurrentPlan();
  }, [userToken.userid]);

  const handleUpgrade = async (planId, planName) => {
    if (currentPlan?.id === planId) return;
    setLoading(planId);
    try {
      const response = await subscribePlan(planId);
      if (response.sessionId) {
        const stripe = await stripePromise;
        stripe.redirectToCheckout({ sessionId: response.sessionId });
      } else if (response.message === 'success') {
        setCurrentPlan({ id: planId, name: planName });
        setMessage('Subscription successful!');
      }
    } catch (error) {
      console.error('Error upgrading plan:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleResubscribe = async () => {
    if (!currentPlan || currentPlan.id === 1) return;
    await handleUpgrade(currentPlan.id, currentPlan.name);
  };

  const isPlanActive = (plan) => {
    return (
      plan.id === currentPlan?.id &&
      new Date(userToken.plan_expiry_date) > new Date()
    );
  };

  return (
    <AppLayout pageId="settings" pageName={"Plans & Billing"}>
      {isLoading || isLoadingPlan || isPageLoading ? (
        <div className="flex justify-center py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      ) : (
        <section>
          {sessionId && (
            <div className="p-4 bg-green-100 text-green-800 rounded-md">
              <h1>Thank you!</h1>
              <p>Enjoy your new subscription plan.</p>
            </div>
          )}
          <div>
            {currentPlan?.id === 1 && userToken.plan_expiry_date && 
              new Date() < new Date(userToken.plan_expiry_date) && (
              <div className="main-bg p-5 rounded-lg mb-5 text-main">
                <div className="flex space-x-2 items-center">
                  <GrStatusInfo size={16} />
                  <span>
                    You have access to Business plan features until{" "}
                    <b>
                      {format(
                        new Date(userToken.plan_expiry_date.replace(" ", "T")),
                        "do MMM yyyy"
                      )}
                    </b>
                    . Please resubscribe to continue with premium features.
                  </span>
                </div>
                <div className="flex mt-3">
                  <button
                    className="btn-submit !rounded-md"
                    onClick={handleResubscribe}
                  >
                    Resubscribe
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 gap-5">
            {plans.map((plan) => (
              <PlanCard
                key={plan.id}
                plan={plan}
                currentPlan={currentPlan.name === plan.name}
                onClick={() => handleUpgrade(plan.id, plan.name)}
                loading={loading}
              />
            ))}
          </div>
        </section>
      )}
    </AppLayout>
  );
};

const PlanCard = ({ plan, currentPlan, onClick, loading }) => {
  const features = plan.features.split(';');

  return (
    <div
      className={`border shadow-lg rounded-lg flex flex-col justify-between ${
        currentPlan ? "bg-gray-50" : ""
      }`}
    >
      <div>
        <div
          className={`px-8 pt-12 pb-8 ${
            plan.name === "Free"
              ? "border-b border-b-gray-100"
              : "bg-main text-white rounded-t-lg"
          }`}
        >
          <h4 className="text-3xl font-bold mb-5">{plan.name}</h4>
          <h4 className="text-4xl font-bold">
            ${plan.price} <span className="text-sm font-normal">USD</span>
          </h4>
          <div className="text-base mt-1">per month</div>
        </div>
        <div className="px-8 py-8">
          <div className="text-lg font-semibold mb-3">Plan highlights:</div>
          <div className="text-[14px] font-medium space-y-2">
            {plan.name === "Business" && (
              <div className="flex items-center text-accent space-x-3">
                <PiStarFour className="text-accent" />
                <span className="text-accent border-b-2 border-b-green-950 border-dotted">
                  Poppiq AI influencer finder
                </span>
              </div>
            )}
            {features.map((feature, index) => (
              <div key={index} className="flex items-center space-x-3">
                <HiCheck className="text-green-600" /> <span>{feature.trim()}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-8 pb-12">
        <button
          type="button"
          className={`btn btn-submit-inv ${
            currentPlan ? "opacity-70" : "bg-main-imp text-white"
          } !text-base !py-3 !font-medium mt-5 w-full flex space-x-3 justify-center`}
          onClick={onClick}
          disabled={currentPlan || loading === plan.id}
        >
          {currentPlan
            ? "Current Plan"
            : plan.id > 1
            ? "Upgrade Now"
            : "Choose Plan"}
          {loading === plan.id && <Spinner className="ml-3" />}
        </button>
      </div>
    </div>
  );
};

export default Plans;
