import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { AppContext } from "../contexts/AppContext";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

const AcceptInvitation = () => {
const { token } = useParams();

  const [error, setError] = useState("");
  const [team, setTeam] = useState("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [formLoading, setformLoading] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { getteaminfo, acceptinvitation } = useContext(AuthContext);
  const { isLoading } = useContext(AppContext);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  
  const resetInputs = () => {
    setCode(["", "", "", "", "", ""]);
    document.querySelectorAll("input")[0].focus();
  };
  
  const getTeamInfo = async () => {
      const response = await getteaminfo(token);
      if(response.message == 'success'){
        setTeam(response.result[0]);
        setEmail(response.customeremail);
      } else {
        setError("Invitation Expired");
      }
  };
  
  const handleacceptinvitation = async () => {
      const response = await acceptinvitation(token);
      if(response.message == 'success'){
        navigate("/onboarding");
      } else {
        setError("Invitation Expired");
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false && formLoading == false) {
      try {
        setformLoading(true);
        if (name !== "" && password !== "") {
          if(password.length >= 6){
            setError("");
            const response = await acceptinvitation(token, password, name);
            if (response.message === "success") {
              navigate("/discover");
            } else {
              setError("Invitation Expired");
            }
          }
          else{
            setError("Password must have at least 6 characters");
          }
        } else {
          setError("Password is required");
          return;
        }
      } catch (error) {
        setError("Failed: " + error);
      } finally {
        setformLoading(false);
      }
    }
  };

  useEffect(() => {
    if (userToken !== null) {
      navigate("/discover");
    }

    getTeamInfo();
  }, []);

  return (
    <div className="auth-screens">
      <div className="block md:flex flex-col overflow-hidden h-full flex-1 justify-between">
        <div>
          <div className="w-full mb-8 relative">
            <div className="flex justify-center">
              <Link to={"/"}>
                <img className="h-9 mx-auto" alt="Poppiq Logo" src="/logo.png" />
              </Link>
            </div>
          </div>
          <div className="text-center">
            <h1 className="heading">Welcome to our Team</h1>
            <h6 className="subheading">We’re excited to invite you to be a part of <span>{team.company}</span> team!</h6>
          </div>
          <div className="w-full max-w-[30rem] section-right mx-auto p-5 accept-invitation">
            <div class="form-section p-10x">
              <div className="pt-0 md:pt-5 pb-5 h-full flex flex-col justify-between mx-auto w-full max-w-[30rem] space-y-10">
                <div>
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="space-y-5"> 
                      <div className="form-group email">
                        <label htmlFor="email">Your Email address</label>
                        <input id="email" name="email" required type="email" className="form-control " value={email} disabled/>
                      </div>
                      <div className="form-group name">
                        <label htmlFor="name">Your Name</label>
                        <input id="name" name="name" required type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} />
                      </div>
                      <div className="form-group password">
                        <label htmlFor="password">Create a password</label>
                        <input id="password" name="password" required type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} value={password} />
                      </div>
                      <div className="flex justify-center">
                        <button type="submit" className="btn btn-main !py-5 mt-4" disabled={formLoading}>
                            {formLoading === false ? "Accept Invitation" : <Bounce className="my-1" animating={formLoading} />}
                        </button>
                      </div>
                    </div>
                    {error !== "" && (
                    <div className="text-center text-red-500 font-semibold text-md mt-5">
                        {error}
                    </div>
                    )}
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-6 justify-center text-neutral-300 text-sm">
          <div>
            <Link className="hover:!underline">
              Privacy
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Terms
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Cookies
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptInvitation;
