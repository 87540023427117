import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "../components/Tooltip";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import Swal from "sweetalert2";

const Earnings = () => {
  const { getmyearnings, setuppaypal, withdrawfunds, userToken, isLoading } = useContext(AppContext);

  const [earnings, setEarnings] = useState([]);
  const [stats, setStats] = useState("");
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [withdrawmodalopen, setwithdrawModalopen] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmemail, setConfirmemail] = useState("");

  const columns = React.useMemo(
    () => [
      { Header: "Date", accessor: "addedatdate" },
      {
        Header: "Campaign",
        accessor: "campaigntitle", // You can use any accessor name here
        // Define the cell content and actions buttons
        Cell: ({ row }) => (
          <Link className="hover:text-black" to={`/assigned-campaigns/${row.original.campaign}`}>
            <u>{row.original.campaigntitle}</u>
          </Link>
        ),
      },
      {
        Header: "Amount",
        accessor: "budget",
        Cell: ({ row }) => <div>${row.original.budget.toLocaleString("en-US")}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => <div>{row.original.status == "Completed" ? row.original.withdrawn == 1 ? row.original.withdrawalstatus == "PENDING" ? <span className="text-yellow-500 tracking-wide font-semibold">Withdraw in progress</span> : row.original.withdrawalstatus == "SUCCESS" ? <span className="text-green-600 tracking-wide font-semibold">Withdrawn</span> : <span className="text-yellow-500 tracking-wide font-semibold">{row.original.withdrawalstatus}</span> : "Ready for Withdrawal" : row.original.status}</div>,
      },
    ],
    []
  );

  const fetchEarnings = async () => {
    const response = await getmyearnings();
    setEarnings(response.earnings);
    setStats(response.stats[0]);
  };

  useEffect(() => {
    fetchEarnings();
  }, []);

  const handleSetup = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (email !== "" && confirmemail !== "") {
          if (email === confirmemail) {
            setError("");
            let response = await setuppaypal(email, confirmemail);
            if (response === "success") {
              setModalopen(false);
              Swal.fire({
                title: "Paypal Setup Completed!",
                text: "You can now withdraw your funds",
                icon: "success",
              });
            }
          } else {
            setError("Emails don't match");
            return;
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        let response = await withdrawfunds();
        if (response === "success") {
          setwithdrawModalopen(false);
          fetchEarnings();
          Swal.fire({
            title: "Withdraw Request Submitted",
            text: "The funds will be transferred shortly.",
            icon: "success",
          });
        }
      } catch (error) {
        setError("Withdraw failed: " + error);
      }
    }
  };

  return (
    <AppLayout pageName={"My Earnings"}>
      {stats != "" ? (
        <>
          {stats.balance_completed !== 0 && stats.balance_completed !== null && (
            <>
              <Transition.Root show={withdrawmodalopen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => setwithdrawModalopen(false)}>
                  <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block" />
                  </Transition.Child>
                  <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                      <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                        <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-xl">
                          <div className="relative w-full items-center overflow-hidden bg-white rounded-lg text-neutral-700 border shadow-xl">
                            <div className="p-5 bg-main text-white text-2xl">Withdraw Funds</div>
                            <button type="button" className="absolute top-6 right-4 text-gray-300 hover:text-gray-500" onClick={() => setwithdrawModalopen(false)}>
                              <span className="sr-only">Close</span>
                              <RxCross2 className="h-5 w-5" aria-hidden="true" />
                            </button>
                            <div className="p-8">
                              <div className="w-full">
                                <div className="text-lg text-neutral-500 leading-relaxed">Review your withdrawal details.</div>
                              </div>
                              <div className="mt-3 mb-5 px-2 border divide-y divide-neutral-200">
                                <div className="p-3">
                                  <div className="text-sm tracking-wide text-neutral-400 mb-0.5">Transfer to</div>
                                  <div className="text-base font-medium text-neutral-700">Paypal ({userToken.paypalmail})</div>
                                </div>
                                <div className="p-3">
                                  <div className="text-sm tracking-wide text-neutral-400 mb-0.5">Amount</div>
                                  <div className="text-base font-medium text-neutral-700">${stats.balance_completed ? stats.balance_completed.toLocaleString() : 0}</div>
                                </div>
                              </div>

                              <div className="flex items-center p-4 text-neutral-500 border-t-4 border-neutral-200 bg-neutral-100" role="alert">
                                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <div className="ms-3 text-sm font-normal">Your payment processor may apply extra fees. Transfers may take up to few business days.</div>
                              </div>

                              {error !== "" ? <div className="text-center text-red-500 font-semibold text-md tracking-wide">{error}</div> : <div className="py-3"></div>}
                              <div className="flex justify-end space-x-5">
                                {isLoading == false && (
                                  <button onClick={() => setwithdrawModalopen(false)} on className="btn-cancel !text-base !font-medium">
                                    Cancel
                                  </button>
                                )}
                                <button onClick={handleWithdraw} className="btn-main !text-base">
                                  {isLoading === false ? "Confirm & withdraw" : <Spinner animating={isLoading} />}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}

          <Transition.Root show={modalopen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setModalopen(false)}>
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block" />
              </Transition.Child>
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                  <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                    <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-2xl">
                      <div className="relative w-full items-center overflow-hidden bg-white rounded-lg text-neutral-700 border shadow-xl">
                        <div className="p-5 bg-main text-white text-2xl">Set up Paypal for Withdrawal</div>
                        <button type="button" className="absolute top-6 right-4 text-gray-300 hover:text-gray-500" onClick={() => setModalopen(false)}>
                          <span className="sr-only">Close</span>
                          <RxCross2 className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <div className="p-8">
                          <div className="w-full">
                            <div className="text-xl text-neutral-500 leading-relaxed">We will use this Paypal account to send you money when you initiate a withdrawal.</div>
                          </div>
                          <form method="post" onSubmit={handleSetup} className="mt-5 space-y-6">
                            <div>
                              <label htmlFor="email">Email Address</label>
                              <input id="email" name="email" required type="email" className="form-control" placeholder="you@example.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
                            </div>
                            <div>
                              <label htmlFor="confirmemail">Repeat Email Address</label>
                              <input id="confirmemail" name="confirmemail" required type="email" className="form-control" placeholder="you@example.com" value={confirmemail} onChange={(e) => setConfirmemail(e.target.value)} maxLength={255} />
                            </div>

                            <div className="flex items-center p-4 text-red-800 border-t-4 border-red-300 bg-red-50" role="alert">
                              <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                              </svg>
                              <div className="ms-3 text-base font-medium">We will not be able to recover funds sent to the wrong address, please make sure the information you enter is correct.</div>
                            </div>

                            {error !== "" ? <div className="text-center text-red-500 font-semibold text-md tracking-wide">{error}</div> : <div className="py-3"></div>}
                            <button type="submit" disabled={isLoading == true || email == "" || confirmemail == "" || email != confirmemail ? true : false} className="btn-main w-full !text-base !py-4 disabled:opacity-70 disabled:cursor-not-allowed disabled:pointer-events-none">
                              {isLoading === false ? "Submit" : <Spinner animating={isLoading} />}
                            </button>
                          </form>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-3">
            <section>
              <h5 className="font-medium text-neutral-700">Available funds</h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_completed ? stats.balance_completed.toLocaleString() : 0}</h1>
              <hr className="my-7" />
              {stats.balance_completed == 0 || stats.balance_completed == null ? (
                <Tooltip text="<span class='text-red-500 mr-1'>⚠</span> Not Enough Funds">
                  <button type="button" className="btn-main-inv mt-1 opacity-80 pointer-events-none cursor-not-allowed" disabled>
                    Withdraw balance
                  </button>
                </Tooltip>
              ) : userToken.paypalmail ? (
                <button type="button" class={`btn-main-inv mt-1`} onClick={() => setwithdrawModalopen(true)}>
                  Withdraw balance
                </button>
              ) : (
                <button type="button" class={`btn-main-inv mt-1`} onClick={() => setModalopen(true)}>
                  Set up Paypal
                </button>
              )}
              <div className="mt-2 text-neutral-400 italic text-xs font-medium">{userToken.paypalmail != null ? "Using Paypal" : "Before Withdrawal"}</div>
            </section>
            <section>
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Payments being cleared</span>
                <Tooltip text="Payments move into your <b className='font-semibold'>Available funds</b> after they clear">
                  <BsInfoCircle color="#666" />
                </Tooltip>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_approved ? stats.balance_approved.toLocaleString() : 0}</h1>
              <hr className="my-7" />
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Payments for active campaigns</span>
                <Tooltip text="Payments move into a clearing period once orders are completed.">
                  <BsInfoCircle color="#666" />
                </Tooltip>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_in_progress ? stats.balance_in_progress.toLocaleString() : 0}</h1>
            </section>
            <section>
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Earnings to date</span>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_earned ? stats.balance_earned.toLocaleString() : 0}</h1>
              <hr className="my-7" />
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Withdrawn to date</span>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_withdrawn ? stats.balance_withdrawn.toLocaleString() : 0}</h1>
            </section>
          </div>

          <section>
            <DataTable className="earnings" data={earnings} columns={columns} />
          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default Earnings;
