import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "../components/Tooltip";

const AllPayments = () => {
  const { getpayments, isLoading } = useContext(AppContext);

  const [payments, setPayments] = useState([]);
  const [stats, setStats] = useState("");

  const columns = React.useMemo(
    () => [
      { Header: "Date", accessor: "addedatdate" },
      {
        Header: "Campaign",
        accessor: "campaigntitle", // You can use any accessor name here
        // Define the cell content and actions buttons
        Cell: ({ row }) => (
          <Link className="hover:text-black" to={`/campaign-details/${row.original.campaign}`}>
            <u>{row.original.campaigntitle}</u>
          </Link>
        ),
      },
      {
        Header: "Amount",
        accessor: "budget",
        Cell: ({ row }) => <div>${row.original.budget.toLocaleString("en-US")}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => <div>{row.original.status == "Completed" ? row.original.withdrawn == 1 ? row.original.pendingpayout == 1 ? <span className="text-yellow-500 tracking-wide font-semibold">Withdraw in progress</span> : <span className="text-green-600 tracking-wide font-semibold">Withdrawn</span> : "Ready for Withdrawal" : row.original.status}</div>,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchPayments = async () => {
      const response = await getpayments();
      setPayments(response.payments);
      setStats(response.stats[0]);
    };

    fetchPayments();
  }, []);

  return (
    <AppLayout pageName={"Payments"}>
      {stats != "" ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-3">
            <section>
              <h5 className="font-medium text-neutral-700">Funds ready for withdrawal</h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_completed != null ? stats.balance_completed.toLocaleString() : 0}</h1>
            </section>
            <section>
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Payments being cleared</span>
                <Tooltip text="Payments move into your <b className='font-semibold'>Available funds</b> after they clear">
                  <BsInfoCircle color="#666" />
                </Tooltip>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_approved != null ? stats.balance_approved.toLocaleString() : 0}</h1>
              <hr className="my-7" />
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Payments for active campaigns</span>
                <Tooltip text="Payments move into a clearing period once orders are completed.">
                  <BsInfoCircle color="#666" />
                </Tooltip>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_in_progress != null ? stats.balance_in_progress.toLocaleString() : 0}</h1>
            </section>
            <section>
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Earnings to date</span>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_earned != null ? stats.balance_earned.toLocaleString() : 0}</h1>
              <hr className="my-7" />
              <h5 className="font-medium text-neutral-700 flex space-x-2">
                <span>Withdrawn to date</span>
              </h5>
              <h1 className="font-bold text-3xl mt-2">${stats.balance_withdrawn != null ? stats.balance_withdrawn.toLocaleString() : 0}</h1>
            </section>
          </div>
          <section>
            <DataTable className="payments" data={payments} columns={columns} />
          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default AllPayments;
