import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { AppContext } from "../contexts/AppContext";

const VerifyEmail = () => {
  const [error, setError] = useState("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const navigate = useNavigate();

  const { verifyotp } = useContext(AuthContext);
  const { isLoading } = useContext(AppContext);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleCodeChange = (e, index) => {
    const value = e.target.value.toUpperCase(); // Force uppercase letters
    if (/^[A-Z0-9]$/.test(value)) { // Allow only alphanumeric
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
  
      // Move focus to the next input field
      if (index < 5 && value) {
        document.querySelectorAll("input")[index + 1].focus();
      }
  
      // Run verification function if all fields are filled
      if (newCode.every((char) => char !== "")) {
        verifyCode(newCode.join(""));
      }
    }
  };
  
  const resetInputs = () => {
    setCode(["", "", "", "", "", ""]);
    document.querySelectorAll("input")[0].focus();
  };
  
  const verifyCode = async (enteredCode) => {

      const response = await verifyotp(enteredCode, userToken.email);
      if(response == 'success'){
        const updatedUserToken = { ...userToken, status: "Active" }; // Change status value accordingly
        localStorage.setItem("userToken", JSON.stringify(updatedUserToken));
        navigate("/onboarding");
      } else {
        setError("Invalid code. Please try again.");
        resetInputs();
      }

  };

  useEffect(() => {
    if (userToken == null) {
      navigate("/");
    }
    else if (userToken.status == 'Active') {
      navigate("/onboarding");
    }
  }, []);

  return (
    <div className="auth-screens">
      <div className="block md:flex flex-col overflow-hidden h-full flex-1 justify-between">
        <div>
          <div className="w-full mb-8 relative">
            <div className="flex justify-center">
              <Link to={"/"}>
                <img className="h-9 mx-auto" alt="Poppiq Logo" src="/logo.png" />
              </Link>
            </div>
          </div>
          <div className="text-center">
            <h1 className="heading">Check your email for a code</h1>
            <h6 className="subheading">We've sent a 6-character code to <span>{userToken.email}</span>. The code expires shortly, so please enter it soon.</h6>
          </div>
          <div className="w-full max-w-[30rem] section-right mx-auto p-5">
            <div class="form-section p-10x">
              <div className="pt-0 md:pt-5 pb-5 h-full flex flex-col justify-between mx-auto w-full max-w-[30rem] space-y-10">
                <div>
                <div className="mb-20">
                    <div className="flex justify-center space-x-2">
                    {[...Array(6)].map((_, index) => (
                        <input
                        key={index}
                        type="text"
                        maxLength="1"
                        className="w-20 h-20 text-center border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                        value={code[index] || ""}
                        onChange={(e) => handleCodeChange(e, index)}
                        onClick={resetInputs}
                        />
                    ))}
                    </div>
                    {error !== "" && (
                    <div className="text-center text-red-500 font-semibold text-md mt-5">
                        {error}
                    </div>
                    )}
                </div>
                <div className="mt-14 flex flex-row justify-center space-x-16">
                    <button className="flex space-x-3 text-black font-normal">
                    <img src="/images/icons/gmail.png"/> <span>Open Gmail</span>
                    </button>
                    <button className="flex space-x-3 text-black font-normal">
                    <img src="/images/icons/outlook.png"/> <span>Open Outlook</span>
                    </button>
                </div>
                <div className="text-center mt-7 font-medium">
                    <div>Can't find your code? Check your spam folder!</div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-6 justify-center text-neutral-300 text-sm">
          <div>
            <Link className="hover:!underline">
              Privacy
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Terms
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Cookies
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
