import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { VscOrganization } from "react-icons/vsc";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsKey } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import "react-phone-number-input/style.css";
import BASE_URL from "../config";
import PhoneInput from "react-phone-number-input";

const Register = () => {
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [codesent, setCodesent] = useState(false);
  const [cont, setCont] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const { signup, verifyotp, isLoading } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      if (password == '') {
        setCont(true)
        return;
      } else if (password.length < 6) {
        setError("Password must have at least 6 characters");
        return;
      }
      try {
        if (email !== "" && password !== "") {
          setError("");
          const response = await signup(email, password);
          if (response === "success") {
            setSuccess(true);
            navigate('/verify')
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Signup failed: " + error);
      }
    }
  };

  const handleCodeChange = (e, index) => {
    const value = e.target.value.toUpperCase(); // Force uppercase letters
    if (/^[A-Z0-9]$/.test(value)) { // Allow only alphanumeric
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
  
      // Move focus to the next input field
      if (index < 5 && value) {
        document.querySelectorAll("input")[index + 1].focus();
      }
  
      // Run verification function if all fields are filled
      if (newCode.every((char) => char !== "")) {
        verifyCode(newCode.join(""));
      }
    }
  };
  
  const resetInputs = () => {
    setCode(["", "", "", "", "", ""]);
    document.querySelectorAll("input")[0].focus();
  };
  
  const verifyCode = async (enteredCode) => {

      const response = await verifyotp(enteredCode);
      if(response == 'success'){
        
      } else {
        setError("Invalid code. Please try again.");
        resetInputs();
      }

  };

  return (
    <AuthLayout page="Register" heading={codesent ? 'Check your email for a code' : 'First, enter your email'} subheading={codesent ? `We've sent a 6-character code to <span>${email}</span>. The code expires shortly, so please enter it soon.` : 'We suggest using the <span>email address you use at work.</span>'}>
        {/* <h3 className="heading">Request an Account</h3> */}
        {codesent === true ? (
          <div>
            {/* 6-digit code input */}
            <div className="mb-20">
              <div className="flex justify-center space-x-2">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="w-20 h-20 text-center border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                    value={code[index] || ""}
                    onChange={(e) => handleCodeChange(e, index)}
                    onClick={resetInputs}
                  />
                ))}
              </div>
              {error !== "" && (
                <div className="text-center text-red-500 font-semibold text-md mt-5">
                  {error}
                </div>
              )}
            </div>
            <div className="mt-14 flex flex-row justify-center space-x-16">
              <button className="flex space-x-3 text-black font-normal">
                <img src="/images/icons/gmail.png"/> <span>Open Gmail</span>
              </button>
              <button className="flex space-x-3 text-black font-normal">
                <img src="/images/icons/outlook.png"/> <span>Open Outlook</span>
              </button>
            </div>
            <div className="text-center mt-7 font-medium">
              <div>Can't find your code? Check your spam folder!</div>
            </div>
          </div>
        ) : (
          <>
          <form className="login-form">
            <div>
              <div className="space-y-5">
                <div className="form-group email">
                  <input id="email" name="email" required type="email" className="form-control" placeholder="name@work-email.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
                </div>
                { cont && 
                <div className="form-group password">
                  <input id="password" name="password" required type="password" className="form-control" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={255} />
                </div>
                }
              </div> 
              {error !== "" ? <div className="text-center text-red-500 font-semibold text-md my-3">{error}</div> : <div className="py-4"></div>}
              <button type="submit" className="w-full btn btn-main-auth disabled:opacity-80" onClick={handleSubmit} disabled={((email == '' && cont == false) || (password == '' && cont == true) ? true : false)}>
                {isLoading === false ? "Continue" : <Bounce animating={isLoading} />}
              </button>
              { !cont && 
              <>
              <div class="or-divider">
                  <hr/>
                  <span>OR</span>
                  <hr/>
              </div>
              <div class="space-y-5">
                <div>
                  <a href={`${BASE_URL}/auth/google`}>
                    <button type="button" className="btn btn-outline-secondary btn-google">
                      <img src="/images/icons/google.png" alt="Google Icon" />
                      <span>Continue With Google</span>
                    </button>
                  </a>
                  <a href={`${BASE_URL}/auth/apple`}>
                    <button type="button" className="btn btn-outline-secondary btn-apple">
                      <img src="/images/icons/apple.png" alt="Apple Icon" />
                      <span>Continue With Apple</span>
                    </button>
                  </a>
                </div>
              </div>
              </>
              }
              <div className="text-center mt-7 text-sm">
                <div className="mb-1">Already using Poppiq? </div>
                <Link className="text-blue-500 font-semibold" to="/">
                  Sign in to an existing account
                </Link>
              </div>
            </div>
          </form>
          </>
        )}
    </AuthLayout>
  );
};

export default Register;
